import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { optionService } from './connected.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class webRTC {
  public logm: string[] = [];
  public captiondom;
  public sock: WebSocket;
  public host; post_edit_port; queue_port; rtp_end_port; rtp_start_port; text_stream_port; webrtc_proxy_port;
  public pc;
  public audioTemp = 0;
  public videoTemp = 0;
  public graphVSize = 0;
  public graphASize = 0;
  public localId; remoteId;

  constructor(private option: optionService,
    private deviceService: DeviceDetectorService) {
  }
  log(msg) {
    this.logm.push(msg);
  }

  openSocket(connectivity) {
    this.host = connectivity.host;
    this.post_edit_port = connectivity.post_edit_port;
    this.queue_port = connectivity.queue_port;
    this.rtp_end_port = connectivity.rtp_end_port;
    this.rtp_start_port = connectivity.rtp_start_port;
    this.text_stream_port = connectivity.text_stream_port;
    this.webrtc_proxy_port = connectivity.webrtc_proxy_port;
    this.sock = new WebSocket('wss://' + this.host + ':' + this.webrtc_proxy_port + '/transcription');
  }

  closeSocket() {
    this.sock.close();
    this.pc.close();
  }

  processAnswer(signaling) {
    if (signaling.response !== 'accepted') {
      // todo Reconnected
    } else {
      this.pc.setRemoteDescription(new RTCSessionDescription({
        type: 'answer',
        sdp: signaling.sdpAnswer
      }));
    }
  }


  createSession() {
    this.pc = new RTCPeerConnection({
      iceServers: [
        {
          urls: 'stun:203.183.172.196:3478'
        }
      ]
    });

    const channel = this.pc.createDataChannel(null);
    channel.onmessage = (event) => {
      const msg = event.data.replace('\u22F3', ' ');
      this.appendMsg(msg);
    };
    this.pc.addTransceiver('video', { direction: 'recvonly' });
    this.pc.addTransceiver('audio', { direction: 'recvonly' });
    this.pc.onicecandidate = event => {
      if (event.candidate != null) {
        const iceSignaling = JSON.stringify({
          id: 'iceCandidate',
          candidate: event.candidate
        });
        console.log(iceSignaling);

        this.sock.send(iceSignaling);
      }
    };

    this.pc.ontrack = (event) => {
      const el = document.getElementById('video') as HTMLVideoElement;
      el.srcObject = event.streams[0];
      el.autoplay = true;
      el.controls = false;
      el.muted = true;
      el.play();


      const ael = document.getElementById('audio') as HTMLVideoElement;
      ael.srcObject = event.streams[0];
      ael.autoplay = true;
      ael.controls = false;
      ael.play();
    };


    this.createOffer('viewer');
  }

  appendMsg(msg: string) {
    // sliding captioned
    this.captiondom = document.getElementById('captioned');
    let url = '' + window.location.href;
    let path = url.split('/');
    let roomid = path[path.length - 1].split('?');



    if (msg === undefined) {
      return;
    } else if (msg === 'Ր') {
      this.captiondom.innerText = '';
      this.option.caption = '';
    } else if (msg.includes('Ց')) { // backspace key
      this.option.caption += msg;
      this.captiondom.innerText = this.option.caption;
      this.captiondom.innerText = this.captiondom.innerText.replace('Ց', '');
      this.option.caption = this.option.caption.replace('Ց', '');
      this.captiondom.innerText = this.captiondom.innerText.slice(0, -1);
      this.option.caption = this.option.caption.slice(0, -1);
      let temp = localStorage.getItem("room_id_" + roomid[0])
      temp = temp.slice(0, -1);
      localStorage.setItem("room_id_" + roomid[0], temp);
    } else if (msg === 'Ո') {
      msg = '';
    } else {
      this.option.caption += msg;
      this.captiondom.innerText = this.option.caption;
      if (!localStorage.getItem("room_id_" + roomid[0])) {
        localStorage.setItem("room_id_" + roomid[0], msg);
      } else {
        localStorage.setItem("room_id_" + roomid[0], localStorage.getItem("room_id_" + roomid[0]) + msg);
      }

      if (this.option.prevScrollHeight < this.captiondom.scrollHeight) {
        this.captiondom.scrollTop = this.captiondom.scrollHeight;
      }

      // this.captiondom.animate();
      this.option.prevScrollHeight = this.captiondom.scrollHeight;
    }
  }

  createOffer(signalingType) {
    this.pc.createOffer({ offerToReceiveVideo: true, offerToReceiveAudio: true }).then(sdpOffer => {
      this.pc.setLocalDescription(sdpOffer);
      const offerSignaling = JSON.stringify({
        id: signalingType,
        sdpOffer: sdpOffer.sdp
      });
      this.sock.send(offerSignaling);
    }).catch(err => (err));
  }
}
