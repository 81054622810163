import { Component, HostListener, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { Connection, GetRoom, optionService } from '../../../_service/connected.service';
import { webRTC } from '../../../_service/webrtc.service';
import { AuthService } from '../../../_service/auth.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.css']
})
export class RoomComponent implements OnInit {
  public noConnection;

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  constructor(private apiService: Connection,
    private route: Router,
    public room: GetRoom,
    public option: optionService,
    public webrtc: webRTC,
    public authService: AuthService,
    private oauthService: OAuthService) { }

  ngOnInit() {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.noConnection = false;
    }));
    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.room.rooms = [];
      this.noConnection = true;
    }));
    document.getElementsByTagName('html')[0].style.backgroundColor = '#FFFFFF';
    this.getRoom();
    setInterval(() => {
      this.getRoom();
    }, 60000);
  }

  getRoom() {
    this.apiService.getAllData().forEach(data => {
      if (window.location.href.includes('broadcast')) {
        this.room.roomData(data['result']);
      } else {
        this.apiService.getAllPostedit('2,3').subscribe((res: any) => {
          console.log(data['result']);
          let peroom = []
          res.data.forEach(room => {
            room.status++;
            peroom.push({
              ...room,
              time: {
                start_time: room.start_time,
                end_time: room.end_time
              }
            })
          });
          data['result'] = data['result'].concat(peroom)
          this.room.roomData(data['result']);
        })
      }
    });
  }

  onKeyUp(event: any) {
    this.apiService.searchData(event.target.value).forEach(data => {
      this.room.roomData(data['result']);
    });
  }

  getData(data) {
    if (data.primary_editor_id) {
      this.route.navigate(['viewv2/' + data.id]);
    } else {
      this.route.navigate(['view/' + data.id]);
    }
    // this.w3_close()

  }
  login() {
    this.authService.login();
  }
  broadcast() {
    this.login();
  }

  logout() {
    this.authService.logout();
  }
}
