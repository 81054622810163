import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Connection, GetRoom, optionService } from '../../../_service/connected.service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { AuthService } from '../../../_service/auth.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-broadcast',
  templateUrl: './broadcast.component.html',
  styleUrls: ['./broadcast.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BroadcastComponent implements OnInit {
  public rooms: any[] = [];
  public startTime: string[][] = [];
  public startDate: string[][] = [];
  public endTime: string[][] = [];
  public endDate: string[][] = [];
  public status: any[] = [];
  public timeStatus: any[] = [];
  public statusColor: any[] = [];
  public noConnection;

  public user;

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  constructor(private apiService: Connection,
    private route: Router,
    private room: GetRoom,
    public option: optionService,
    public authService: AuthService,
    private oauthService: OAuthService
  ) { }

  ngOnInit() {
    this.authService.getUserByUsername(this.authService.getUser().username).subscribe((res) => {
      this.user = res;
      this.onlineEvent = fromEvent(window, 'online');
      this.offlineEvent = fromEvent(window, 'offline');
      this.subscriptions.push(this.onlineEvent.subscribe(e => {
        this.noConnection = false;
      }));

      this.subscriptions.push(this.offlineEvent.subscribe(e => {
        this.rooms = [];
        this.noConnection = true;
      }));
      document.getElementsByTagName('html')[0].style.backgroundColor = '#11151E';
      this.getRoom();
      setInterval(() => {
        this.getRoom();
      }, 60000);
    })
  }

  getRoom() {
    this.apiService.getDataByowner(this.user['id']).subscribe(data => {
      this.room.roomData(data['result']);
      this.rooms = this.room.rooms;
      this.startTime = this.room.startTime;
      this.startDate = this.room.startDate;
      this.endTime = this.room.endTime;
      this.endDate = this.room.endDate;
      this.statusColor = this.room.statusColor;
      this.status = this.room.status;
      this.timeStatus = this.room.timeStatus;
    });
  }

  onKeyUp(event: any) {
    this.apiService.searchData(event.target.value).subscribe((data: any) => {
      console.log(data);
      data = data['result'].filter(room => room.owner.id == this.user['id'])
      this.room.roomData(data);
      this.rooms = this.room.rooms;
      this.startTime = this.room.startTime;
      this.startDate = this.room.startDate;
      this.endTime = this.room.endTime;
      this.endDate = this.room.endDate;
      this.statusColor = this.room.statusColor;
      this.status = this.room.status;
      this.timeStatus = this.room.timeStatus;
    });
  }

  getData(id) {
    // this.w3_close()
    this.route.navigate(['broadcast/' + id]);
  }
  logout() {
    this.authService.logout();
  }
}
