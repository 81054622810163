import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShowComponent } from './components/pc/desktop-show/show.component';
import { RoomComponent } from './components/pc/desktop-room/room.component';
import { BroadcastComponent } from './components/pc/desktop-broadcast/broadcast.component';
import { MobileRoomComponent } from './components/mobile/mobile-room/mobile-room.component';
import { MobileShowComponent } from './components/mobile/mobile-show/mobile-show.component';
import { AuthGuardService, DesktopGuard, MobileGuard } from './_service/auth.guard.service';
import { PrivilegeGuard } from './_service/privilege.guard';
import { P404Component } from './components/error/404.component';



const routes: Routes = [
  {
    path: '404',
    component: P404Component,
    canActivate: [DesktopGuard, AuthGuardService],
  },
  {
    path: 'view',
    component: RoomComponent,
    canActivate: [DesktopGuard],
    children: [
      {
        path: ':roomid',
        component: ShowComponent
      }
    ]
  },
  {
    path: 'viewv2',
    component: RoomComponent,
    canActivate: [DesktopGuard],
    children: [
      {
        path: ':roomid',
        component: ShowComponent
      }
    ]
  },
  {
    path: 'viewm',
    component: MobileRoomComponent,
    children: [
      {
        path: ':roomid',
        component: MobileShowComponent
      }
    ]
  },
  {
    path: 'broadcast',
    component: BroadcastComponent,
    canActivate: [AuthGuardService, PrivilegeGuard],
    children: [
      {
        path: ':roomid',
        component: ShowComponent
      }
    ],

  },

  { path: '', redirectTo: '/view', pathMatch: 'full' },
  {
    path: 'channel',
    children: [
      {
        path: ':user',
        component: ShowComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/view'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
