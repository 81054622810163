import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Connection, optionService, GetRoom } from '../../../_service/connected.service';
import { webRTC } from '../../../_service/webrtc.service';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})

export class ShowComponent implements OnInit, OnDestroy, AfterViewInit {

  public id: number;
  public data;
  public isMobile = false;
  public margin; topMargin;

  // Show & Hide relate classes
  public beforeStreaming;
  public broadcaster = 'hide';
  public viewer = 'show-inline';
  public start = false;

  // WebSocket
  public logm: string[] = [];
  public isBroadcast = false;
  public broadcast;

  constructor(private route: ActivatedRoute,
    private apiService: Connection,
    public option: optionService,
    public webrtc: webRTC,
    private room: GetRoom
  ) {
  }

  log(msg) {
    this.logm.push(msg);
  }

  ngOnInit() {
    this.start = false;
    this.viewerStop();
    if (/Android|iPhone/i.test(window.navigator.userAgent)) {
      this.isMobile = true;
      this.margin = '0px';
      this.topMargin = '0px';

    } else {
      this.isMobile = false;
      this.margin = '300px';
      this.topMargin = '70px';
    }

  }

  ngAfterViewInit() {

    if (window.location.href.includes('channel')) {
      this.margin = '0px';
      this.topMargin = '0px';
      this.route.paramMap.subscribe(paramMap => {
        this.apiService.getFixedRoomId(paramMap.get('user')).subscribe((roomid: any) => {
          this.apiService.getData(roomid.GET).subscribe((room) => {
            this.showRoomData(room);
            setTimeout(() => { this.viewerStart('no'); }, 1000);
          });
        });
      });


    } else {

      this.route.paramMap.subscribe(paramMap => {
        this.viewerStop();
        this.id = +paramMap.get('roomid');
        if (location.href.includes('viewv2')) {
          this.apiService.getPosteditById(this.id).subscribe((data) => {
            console.log(data);
            let room = {
              ...data,
              description: '',
              owner: {
                firstname: 'ศูนย์บริการคำบรรยายแทนเสียง'
              },
              time: {
                start_time: data['start_time'],
                end_time: data['end_time'],
              },
              connectivity: {
                ...data['connectivity'],
                host: 'api.sandbox.rtt.in.th'
              }
            }
            this.showRoomData(room);
          })
        } else {
          this.apiService.getData(this.id).subscribe((data) => {
            console.log(data);

            if (window.location.href.includes('broadcast')) {
              document.getElementById('content').style.color = 'white';
            }
            this.showRoomData(data);
          });
        }


      });

    }
  }


  ngOnDestroy() {
    this.viewerStop();
  }

  showRoomData(room) {
    const i = this.room.showRoom(room);
    this.option.id = room.id;
    this.option.data = this.data = room;
    this.option.title = this.room.title;
    this.option.description = this.room.description;
    this.option.owner = this.data.owner.firstname;
    this.option.startTime = this.data.startTime;
    this.option.endTime = this.data.endTime;
    if (this.webrtc.pc != null) {
      this.webrtc.pc.close();
      this.webrtc.sock.close();
      this.webrtc.pc = null;
      this.webrtc.sock = null;
    }
    this.webrtc.openSocket(this.data.connectivity);

    if (window.location.href.includes('broadcast')) {
      this.broadcaster = 'show-inline';
      this.viewer = 'hide';
      this.isBroadcast = true;
    }
    this.option.startTime = this.room.Stime;
    this.option.endTime = this.room.Etime;
    this.option.status = this.room.status[i];
  }


  // Viewer section //

  viewerStart(broadcast) {
    this.start = true;
    this.beforeStreaming = 'hide';
    this.broadcast = broadcast;
  }

  viewerStop() {
    window.scrollTo(0, 0);
    this.beforeStreaming = 'show-block';

    this.start = false;
  }

}

