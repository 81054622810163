import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { Connection, optionService, GetRoom } from '../../../_service/connected.service';
import { DomSanitizer } from '@angular/platform-browser';
import { webRTC } from '../../../_service/webrtc.service';
import { Broadcast } from '../../../_service/broadcast.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Chart, ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('fullScreen', { static: false }) fullScreen;
  @Input() id;
  @Input() isBroadcast;

  public status: any;

  public videoArea;

  // Show & Hide relate classes

  public liveContainer; statusStreaming;
  public video; videoTextOnly; viewClass;
  public unmuteVolume;
  public muteVolume = false;
  public chart = false;
  public mic = true;
  public isFix = false;

  // Fullscreen

  public exitFullscreen: any;
  public fullscreenHeight = 'normal-height';
  public isFullscreen = false;
  public timeout: any = null;
  public time = 0;
  public checkTextOutputInterval: any;
  // websocket
  public sock: any;
  public gotoview: any;
  public number: any;
  public stat: any;
  public lineChart: any;

  // browser type
  public browser;
  public isMobile;
  public isTablet;

  constructor(
    public option: optionService,
    public webrtc: webRTC,
    public broadcast: Broadcast,
    private sanitizer: DomSanitizer,
    private deviceService: DeviceDetectorService
  ) {
  }

  ngOnDestroy() {
    clearTimeout(this.timeout);
    this.videoArea = 'cursor-show';
    this.viewClass = 'liveContainer';
    this.option.caption = '';
    clearInterval(this.stat);
    clearInterval(this.checkTextOutputInterval)
    this.sock.close();
  }

  ngOnInit() {

    this.browser = this.deviceService.getDeviceInfo().browser
    this.isMobile = this.deviceService.isMobile();
    this.option.isTablet = this.deviceService.isTablet();
    this.liveContainer = 'show-block';
    this.videoArea = 'cursor-show';
    this.viewClass = 'liveContainer';
    this.checkTextOutputInterval = setInterval(() => {
      this.checkTextOutput();
    }, 300000)
  }

  ngAfterViewInit() {
    clearTimeout(this.timeout);
    this.option.caption = '';
    this.sock = this.webrtc.sock;
    if (window.location.href.includes('channel')) {
      this.isFix = true;
      setTimeout(() => {
        document.getElementById('statusStreaming').style.display = 'none';
        document.getElementById('option').style.display = 'none';
      }, 1000);

    }
    if (window.location.href.includes('broadcast')) {
      setTimeout(() => {
        this.viewClass = 'liveContainerBroadcast';
        this.chart = true;
        this.option.fullscreenHeight = '';
      }, 0);



      if (this.isBroadcast === 'playback') {
        this.broadcast.playback(this.option.data);
        //this.setWebrtc();

      }
      if (this.isBroadcast === 'video') {
        this.broadcast.videoBroadcast(this.option.data);
      }
      if (this.isBroadcast === 'audio') {
        this.broadcast.audioBroadcast(this.option.data);
      }

      setTimeout(() => {
        this.option.setGraph();
      }, 999);

      if (this.isBroadcast !== 'playback') {
        this.setWebrtc();

        setInterval(() => {
          this.broadcast.getstats();
          this.option.status = 'กำลังทำการถ่ายทอดสด';
        }, 1000);
      }
      // else {
      //   setInterval(() => {
      //     this.webrtc.getstat();
      //   }, 1000);
      // }
    } else {
      this.setWebrtc();
    }
  }

  checkTextOutput() {
    let captiondom = document.getElementById('captioned');
    if (captiondom) {
      if (captiondom.innerText.length > 1000) {
        this.option.caption = this.option.caption.slice(this.option.caption.length - 500, this.option.caption.length);
        captiondom.innerText = captiondom.innerText.slice(captiondom.innerText.length - 500, captiondom.innerText.length)
      }
    }
  }

  setWebrtc() {
    this.webrtc.createSession();


    this.sock.onclose = (e) => {
      const log = 'disconnected from server';
    };
    this.sock.onerror = (e) => {
      console.log(e);
    }
    this.sock.onmessage = (e) => {
      const signaling = JSON.parse(e.data);
      if (signaling.id === 'stat') {
        this.option.number = signaling.numberOfClient;
      }
      if (signaling.id === 'viewerResponse') {
        this.webrtc.processAnswer(signaling);
      } else if (signaling.id === 'iceCandidate') {
        this.webrtc.pc.addIceCandidate(signaling.candidate);
      } else if (signaling.id === 'status') {
        alert(`error while joining '${signaling.code}' - '${signaling.message}'`);
      }
    }
  }

  toFullScreen() {

    this.option.exitFullscreen = 'show-inline';
    this.option.full = 'hide';
    this.option.fullscreenHeight = this.fullscreenHeight = 'full-height';
    this.isFullscreen = this.option.isFullscreen = true;
    this.time = 3000;

    const elem = this.fullScreen.nativeElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    }
  }

  exitFullScreen() {
    this.time = 0;
    this.option.exitFullscreen = 'hide';
    this.option.full = 'show-inline';
    this.option.fullscreenHeight = this.fullscreenHeight = 'normal-height';
    this.isFullscreen = this.option.isFullscreen = false;
    this.videoArea = 'cursor-show';
    clearTimeout(this.timeout);
    if (document['exitFullscreen']) {
      document['exitFullscreen']();
    } else if (document['webkitExitFullscreen']) {
      document['webkitExitFullscreen']();
    } else if (document['mozCancelFullScreen']) {
      document['mozCancelFullScreen']();
    } else if (document['msExitFullscreen']) {
      document['msExitFullscreen']();
    }
  }

  enter() {
    this.option.statusStreaming = this.option.terminateViewer = this.option.options = 'show-smooth';
    this.option.mouseEnter = true;
  }

  leave() {
    this.option.mouseEnter = false;
    if (!this.option.optionPiority) {
      this.option.statusStreaming = this.option.terminateViewer = this.option.options = 'hide-smooth';
    }
  }

  mouseOverVideo() {
    clearTimeout(this.timeout);

    if (this.isFullscreen) {
      if (this.option.optionPiority || this.option.mouseOptionEnter) {
        this.option.statusStreaming = this.option.terminateViewer = this.option.options = 'show-smooth';
        this.videoArea = 'cursor-show';
      } else if (!this.option.mouseOptionEnter) {
        this.option.statusStreaming = this.option.terminateViewer = this.option.options = 'show-smooth';
        this.videoArea = 'cursor-show';
        this.timeout = setTimeout(() => {
          this.option.statusStreaming = this.option.terminateViewer = this.option.options = 'hide-smooth';
          this.videoArea = 'cursor-hide';
        }, this.time);
      }
    } else {
      this.videoArea = 'cursor-show';
    }
  }
  micOn() {
    this.mic = true;
  }

  micOff() {
    this.mic = false;
  }
  sanitize(url: string, port: any) {
    return this.sanitizer.bypassSecurityTrustUrl(url + ':' + port);
  }



}
